







import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
import centrifugeClientFactory from "@/services/centrifugeClientFactory";
import merge from "@common/modules/vuetifyx/utils/merge";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import QRCode from "qrcode";
import { downloadUri } from "@common/util/download";
import userManager from "@/services/userManager";

export default Vue.extend({
  props: ["item"],
  data() {
    const self = this;
    const self2 = this;
    return {
      self,
      updatedItem: null,
      tableContainer: new DataContainer(),
      tableOptions: {
        attrs: {
          "item-key": "connectorId",
          "hide-default-footer": true,
        },
        content: {
          displayFields: {
            connectorId: {
              text: "Connector ID",
            },
            qrCode: {
              text: "QR Code",
              options: {
                transform(_, item) {
                  const connectorId = item.connectorId;
                  const setting =
                    self.item.connectorSettingItems?.[connectorId - 1];
                  return setting && setting.qrCode;
                },
              },
            },
            status: {
              text: "Status",
              options: {
                label: true,
                attrs: {
                  color: "primary",
                  outlined: true,
                },
              },
            },
            type: {
              text: "Type",
              options: {
                label: true,
                attrs: {
                  color: "info",
                  outlined: true,
                },
              },
            },
            action: {
              text: "Actions",
            },
          },
          template: {
            itemActionButtons: {
              startCharging: {
                content: {
                  icon: "mdi-play-circle",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Start Charging",
                    },
                  },
                  confirm: {
                    content: {
                      content: {
                        html: "Are you sure you want to start charging?",
                      },
                    },
                    on: {
                      async xYes({ self }) {
                        const yesBtn = self.yesBtnContainer.value;
                        const btn = self.context();
                        const { dataTable, item } = btn.context();
                        const { item: parentItem } = dataTable.context();
                        yesBtn.loading.value = true;
                        const result = await dataTable.execute(
                          async () => {
                            return await coreApiClient.call(
                              "chargingStations",
                              "startCharging",
                              {
                                id: parentItem._id,
                                connectorId: item.connectorId,
                              }
                            );
                          },
                          undefined,
                          {
                            disableLoading: true,
                            willRefresh: true,
                          }
                        );
                        yesBtn.loading.value = false;
                        if (result) {
                          self.hide();
                        }
                      },
                    },
                  },
                },
                ext: {
                  condition(dataTable, item) {
                    return ["Available", "Preparing"].includes(item.status);
                  },
                },
              },
              stopCharging: {
                content: {
                  icon: "mdi-stop-circle",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Stop Charging",
                    },
                  },
                  confirm: {
                    content: {
                      content: {
                        html: "Are you sure you want to stop charging?",
                      },
                    },
                    on: {
                      async xYes({ self }) {
                        const yesBtn = self.yesBtnContainer.value;
                        const btn = self.context();
                        const { dataTable, item } = btn.context();
                        const { item: parentItem } = dataTable.context();
                        yesBtn.loading.value = true;
                        const result = await dataTable.execute(
                          async () => {
                            return await coreApiClient.call(
                              "chargingStations",
                              "stopCharging",
                              {
                                id: parentItem._id,
                                connectorId: item.connectorId,
                              }
                            );
                          },
                          undefined,
                          {
                            disableLoading: true,
                            willRefresh: true,
                          }
                        );
                        yesBtn.loading.value = false;
                        if (result) {
                          self.hide();
                        }
                      },
                    },
                  },
                },
                ext: {
                  condition(dataTable, item) {
                    return ["Charging"].includes(item.status);
                  },
                },
              },
              viewChargingDetails: {
                content: {
                  icon: "mdi-battery-charging",
                },
                on: {
                  xClick({ self }) {
                    const { dataTable, item } = self.context();
                    const { $router, item: parentItem } = dataTable.context();
                    $router.push({
                      path: `/app/charging_details/${parentItem._id}/${item.connectorId}`,
                    });
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "View Charging Details",
                    },
                  },
                },
              },
              viewConnectorDetails: !self.item.isPrivate && {
                content: {
                  icon: "mdi-connection",
                },
                on: {
                  xClick({ self }) {
                    const { dataTable, item } = self.context();
                    const { $router, item: parentItem } = dataTable.context();
                    $router.push({
                      path: `/app/charging_stations/${parentItem._id}/${item.connectorId}`,
                    });
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "View Connector Details",
                    },
                  },
                },
              },
              downloadQr: !self.item.isPrivate && {
                content: {
                  icon: "mdi-qrcode",
                },
                on: {
                  async xClick({ self }) {
                    const { dataTable, item } = self.context();
                    const { item: chargingStation } = dataTable.context();
                    const setting = self2.item.connectorSettingItems[item.connectorId - 1];
                    const qrCode = setting && setting.qrCode;
                    const dataUrl = await QRCode.toDataURL(qrCode, {
                      width: 500,
                    });
                    downloadUri(
                      dataUrl,
                      `charging_station_${chargingStation.uniqueId}_${item.connectorId}.png`
                    );
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Download QR Code",
                    },
                  },
                },
              },
              downloadQr2: self.item.isPrivate && {
                content: {
                  icon: "mdi-qrcode",
                },
                on: {
                  async xClick({ self }) {
                    const { item } = self.context();
                    const setting =
                      self2.item.connectorSettingItems?.[item.connectorId - 1];
                    const qrCode = setting && setting.qrCode;
                    const dataUrl = await QRCode.toDataURL(qrCode, {
                      width: 500,
                    });
                    downloadUri(
                      dataUrl,
                      `charging_station_${self2.item.uniqueId}_${item.connectorId}.png`
                    );
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Download QR Code (Private)",
                    },
                  },
                },
                ext: {
                  condition(dataTable, item) {
                    const setting =
                      self.item.connectorSettingItems?.[item.connectorId - 1];
                    return setting && setting.qrCode;
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll() {
              let items =
                (self.updatedItem.details &&
                  self.updatedItem.details.connectors) ||
                [];
              if (userManager.checkRole(["user"], true)) {
                items = items.filter(({ connectorId }) => {
                  const connectorItem = self.item.connectorItems.find(
                    ({ connectorId: id }) => id === connectorId
                  );
                  return (
                    connectorItem?.activeUserId ===
                    userManager.getUserInfo()._id
                  );
                });
              }
              return [items, items.length];
            },
          },
        },
      },
    };
  },
  created() {
    const self = this;
    const channelName = `private-chargingStations-tenantId=${this.item.tenantId}-uniqueId=${this.item.uniqueId}`;
    const socketId = userManager.getUserInfo()._id;

    const centrifuge = centrifugeClientFactory(null, async () => {
      const {
        auth
      } = await coreApiClient.call("chargingStations", "subscribe", {
        socketId,
        channelName,
        id: self.item._id,
      });
      return auth;
    });
    this.centrifuge = centrifuge;

    this.updatedItem = { ...this.item };

    const sub = centrifuge.newSubscription(channelName);
    sub.on("publication", (ctx) => {
      const data = (ctx.data);
      self.$set(self, "updatedItem", merge.clone(self.updatedItem, data));
      self.tableContainer.value.refresh();
    });
    sub.subscribe();
    centrifuge.connect();
  },
  destroyed() {
    if (this.centrifuge) {
      this.centrifuge.disconnect();
    }
  },
});
